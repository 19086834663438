.epg {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;
  text-align: center;
  &-details {
    padding: 1rem;
  }
}
