* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: auto;
  width: 100%;
}

html, body {
  overflow: hidden;
  height: 100%;
  overscroll-behavior-y: none;
}

.app-is__native .app-back-button {
  background-image: url(/static/media/sprite.0c1e8caf.png);
  background-position: -1182px -603px;
}

.app-is__native .home-page .app-content-area {
  top: 0;
}

.app-is__native .app-container .app-page-title {
  display: none;
}

.app-is__native .node-title {
  display: block;
  color: var(--text);
  font-weight: bold;
}

.app-is__native .app-back-button {
  margin: 0
}

.app-is__native .app-back-button-wrapper {
  top: 0
}

.app-is__native .app-header {
  background-color: white;  
  border-bottom: 1px solid #fff3f5;
  box-shadow: none;
}


