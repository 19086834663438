@import "@base-styles/_colors";


.preview-mode-text {
  color: white;
  font-size: 1rem;
}

.action-link {
  color: $care;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
}

.preview-mode {
  text-align: center;
  padding: 0px 6px 0px 6px;
}

@media screen and (max-width: 575px) {
  .action-link {
    display: block;
  }
  .preview-mode-text, .action-link {
    font-size: 0.8rem;
  }
}