// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$back-inverted-name: 'back-inverted';
$back-inverted-x: 1182px;
$back-inverted-y: 531px;
$back-inverted-offset-x: -1182px;
$back-inverted-offset-y: -531px;
$back-inverted-width: 48px;
$back-inverted-height: 48px;
$back-inverted-total-width: 1230px;
$back-inverted-total-height: 798px;
$back-inverted-image: '~sprite.png';
$back-inverted: (1182px, 531px, -1182px, -531px, 48px, 48px, 1230px, 798px, '~sprite.png', 'back-inverted', );
$back-inverted-2x-name: 'back-inverted@2x';
$back-inverted-2x-x: 750px;
$back-inverted-2x-y: 531px;
$back-inverted-2x-offset-x: -750px;
$back-inverted-2x-offset-y: -531px;
$back-inverted-2x-width: 72px;
$back-inverted-2x-height: 72px;
$back-inverted-2x-total-width: 1230px;
$back-inverted-2x-total-height: 798px;
$back-inverted-2x-image: '~sprite.png';
$back-inverted-2x: (750px, 531px, -750px, -531px, 72px, 72px, 1230px, 798px, '~sprite.png', 'back-inverted@2x', );
$back-name: 'back';
$back-x: 1182px;
$back-y: 603px;
$back-offset-x: -1182px;
$back-offset-y: -603px;
$back-width: 48px;
$back-height: 48px;
$back-total-width: 1230px;
$back-total-height: 798px;
$back-image: '~sprite.png';
$back: (1182px, 603px, -1182px, -603px, 48px, 48px, 1230px, 798px, '~sprite.png', 'back', );
$back-2x-name: 'back@2x';
$back-2x-x: 822px;
$back-2x-y: 531px;
$back-2x-offset-x: -822px;
$back-2x-offset-y: -531px;
$back-2x-width: 72px;
$back-2x-height: 72px;
$back-2x-total-width: 1230px;
$back-2x-total-height: 798px;
$back-2x-image: '~sprite.png';
$back-2x: (822px, 531px, -822px, -531px, 72px, 72px, 1230px, 798px, '~sprite.png', 'back@2x', );
$chevron-down-small-inverted-name: 'chevron-down-small-inverted';
$chevron-down-small-inverted-x: 750px;
$chevron-down-small-inverted-y: 675px;
$chevron-down-small-inverted-offset-x: -750px;
$chevron-down-small-inverted-offset-y: -675px;
$chevron-down-small-inverted-width: 48px;
$chevron-down-small-inverted-height: 48px;
$chevron-down-small-inverted-total-width: 1230px;
$chevron-down-small-inverted-total-height: 798px;
$chevron-down-small-inverted-image: '~sprite.png';
$chevron-down-small-inverted: (750px, 675px, -750px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'chevron-down-small-inverted', );
$chevron-down-small-inverted-2x-name: 'chevron-down-small-inverted@2x';
$chevron-down-small-inverted-2x-x: 894px;
$chevron-down-small-inverted-2x-y: 531px;
$chevron-down-small-inverted-2x-offset-x: -894px;
$chevron-down-small-inverted-2x-offset-y: -531px;
$chevron-down-small-inverted-2x-width: 72px;
$chevron-down-small-inverted-2x-height: 72px;
$chevron-down-small-inverted-2x-total-width: 1230px;
$chevron-down-small-inverted-2x-total-height: 798px;
$chevron-down-small-inverted-2x-image: '~sprite.png';
$chevron-down-small-inverted-2x: (894px, 531px, -894px, -531px, 72px, 72px, 1230px, 798px, '~sprite.png', 'chevron-down-small-inverted@2x', );
$chevron-down-small-name: 'chevron-down-small';
$chevron-down-small-x: 798px;
$chevron-down-small-y: 675px;
$chevron-down-small-offset-x: -798px;
$chevron-down-small-offset-y: -675px;
$chevron-down-small-width: 48px;
$chevron-down-small-height: 48px;
$chevron-down-small-total-width: 1230px;
$chevron-down-small-total-height: 798px;
$chevron-down-small-image: '~sprite.png';
$chevron-down-small: (798px, 675px, -798px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'chevron-down-small', );
$chevron-down-small-2x-name: 'chevron-down-small@2x';
$chevron-down-small-2x-x: 966px;
$chevron-down-small-2x-y: 531px;
$chevron-down-small-2x-offset-x: -966px;
$chevron-down-small-2x-offset-y: -531px;
$chevron-down-small-2x-width: 72px;
$chevron-down-small-2x-height: 72px;
$chevron-down-small-2x-total-width: 1230px;
$chevron-down-small-2x-total-height: 798px;
$chevron-down-small-2x-image: '~sprite.png';
$chevron-down-small-2x: (966px, 531px, -966px, -531px, 72px, 72px, 1230px, 798px, '~sprite.png', 'chevron-down-small@2x', );
$chevron-up-small-inverted-name: 'chevron-up-small-inverted';
$chevron-up-small-inverted-x: 846px;
$chevron-up-small-inverted-y: 675px;
$chevron-up-small-inverted-offset-x: -846px;
$chevron-up-small-inverted-offset-y: -675px;
$chevron-up-small-inverted-width: 48px;
$chevron-up-small-inverted-height: 48px;
$chevron-up-small-inverted-total-width: 1230px;
$chevron-up-small-inverted-total-height: 798px;
$chevron-up-small-inverted-image: '~sprite.png';
$chevron-up-small-inverted: (846px, 675px, -846px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'chevron-up-small-inverted', );
$chevron-up-small-inverted-2x-name: 'chevron-up-small-inverted@2x';
$chevron-up-small-inverted-2x-x: 1038px;
$chevron-up-small-inverted-2x-y: 531px;
$chevron-up-small-inverted-2x-offset-x: -1038px;
$chevron-up-small-inverted-2x-offset-y: -531px;
$chevron-up-small-inverted-2x-width: 72px;
$chevron-up-small-inverted-2x-height: 72px;
$chevron-up-small-inverted-2x-total-width: 1230px;
$chevron-up-small-inverted-2x-total-height: 798px;
$chevron-up-small-inverted-2x-image: '~sprite.png';
$chevron-up-small-inverted-2x: (1038px, 531px, -1038px, -531px, 72px, 72px, 1230px, 798px, '~sprite.png', 'chevron-up-small-inverted@2x', );
$chevron-up-small-name: 'chevron-up-small';
$chevron-up-small-x: 894px;
$chevron-up-small-y: 675px;
$chevron-up-small-offset-x: -894px;
$chevron-up-small-offset-y: -675px;
$chevron-up-small-width: 48px;
$chevron-up-small-height: 48px;
$chevron-up-small-total-width: 1230px;
$chevron-up-small-total-height: 798px;
$chevron-up-small-image: '~sprite.png';
$chevron-up-small: (894px, 675px, -894px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'chevron-up-small', );
$chevron-up-small-2x-name: 'chevron-up-small@2x';
$chevron-up-small-2x-x: 1110px;
$chevron-up-small-2x-y: 531px;
$chevron-up-small-2x-offset-x: -1110px;
$chevron-up-small-2x-offset-y: -531px;
$chevron-up-small-2x-width: 72px;
$chevron-up-small-2x-height: 72px;
$chevron-up-small-2x-total-width: 1230px;
$chevron-up-small-2x-total-height: 798px;
$chevron-up-small-2x-image: '~sprite.png';
$chevron-up-small-2x: (1110px, 531px, -1110px, -531px, 72px, 72px, 1230px, 798px, '~sprite.png', 'chevron-up-small@2x', );
$circle-exclamation-name: 'circle-exclamation';
$circle-exclamation-x: 1200px;
$circle-exclamation-y: 480px;
$circle-exclamation-offset-x: -1200px;
$circle-exclamation-offset-y: -480px;
$circle-exclamation-width: 24px;
$circle-exclamation-height: 24px;
$circle-exclamation-total-width: 1230px;
$circle-exclamation-total-height: 798px;
$circle-exclamation-image: '~sprite.png';
$circle-exclamation: (1200px, 480px, -1200px, -480px, 24px, 24px, 1230px, 798px, '~sprite.png', 'circle-exclamation', );
$circle-exclamation-2x-name: 'circle-exclamation@2x';
$circle-exclamation-2x-x: 942px;
$circle-exclamation-2x-y: 675px;
$circle-exclamation-2x-offset-x: -942px;
$circle-exclamation-2x-offset-y: -675px;
$circle-exclamation-2x-width: 48px;
$circle-exclamation-2x-height: 48px;
$circle-exclamation-2x-total-width: 1230px;
$circle-exclamation-2x-total-height: 798px;
$circle-exclamation-2x-image: '~sprite.png';
$circle-exclamation-2x: (942px, 675px, -942px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'circle-exclamation@2x', );
$circle-exclamation-3x-name: 'circle-exclamation@3x';
$circle-exclamation-3x-x: 750px;
$circle-exclamation-3x-y: 603px;
$circle-exclamation-3x-offset-x: -750px;
$circle-exclamation-3x-offset-y: -603px;
$circle-exclamation-3x-width: 72px;
$circle-exclamation-3x-height: 72px;
$circle-exclamation-3x-total-width: 1230px;
$circle-exclamation-3x-total-height: 798px;
$circle-exclamation-3x-image: '~sprite.png';
$circle-exclamation-3x: (750px, 603px, -750px, -603px, 72px, 72px, 1230px, 798px, '~sprite.png', 'circle-exclamation@3x', );
$envelope-name: 'envelope';
$envelope-x: 1200px;
$envelope-y: 504px;
$envelope-offset-x: -1200px;
$envelope-offset-y: -504px;
$envelope-width: 24px;
$envelope-height: 24px;
$envelope-total-width: 1230px;
$envelope-total-height: 798px;
$envelope-image: '~sprite.png';
$envelope: (1200px, 504px, -1200px, -504px, 24px, 24px, 1230px, 798px, '~sprite.png', 'envelope', );
$envelope-2x-name: 'envelope@2x';
$envelope-2x-x: 990px;
$envelope-2x-y: 675px;
$envelope-2x-offset-x: -990px;
$envelope-2x-offset-y: -675px;
$envelope-2x-width: 48px;
$envelope-2x-height: 48px;
$envelope-2x-total-width: 1230px;
$envelope-2x-total-height: 798px;
$envelope-2x-image: '~sprite.png';
$envelope-2x: (990px, 675px, -990px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'envelope@2x', );
$error-name: 'error';
$error-x: 1182px;
$error-y: 579px;
$error-offset-x: -1182px;
$error-offset-y: -579px;
$error-width: 24px;
$error-height: 24px;
$error-total-width: 1230px;
$error-total-height: 798px;
$error-image: '~sprite.png';
$error: (1182px, 579px, -1182px, -579px, 24px, 24px, 1230px, 798px, '~sprite.png', 'error', );
$error-2x-name: 'error@2x';
$error-2x-x: 1038px;
$error-2x-y: 675px;
$error-2x-offset-x: -1038px;
$error-2x-offset-y: -675px;
$error-2x-width: 48px;
$error-2x-height: 48px;
$error-2x-total-width: 1230px;
$error-2x-total-height: 798px;
$error-2x-image: '~sprite.png';
$error-2x: (1038px, 675px, -1038px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'error@2x', );
$error-3x-name: 'error@3x';
$error-3x-x: 822px;
$error-3x-y: 603px;
$error-3x-offset-x: -822px;
$error-3x-offset-y: -603px;
$error-3x-width: 72px;
$error-3x-height: 72px;
$error-3x-total-width: 1230px;
$error-3x-total-height: 798px;
$error-3x-image: '~sprite.png';
$error-3x: (822px, 603px, -822px, -603px, 72px, 72px, 1230px, 798px, '~sprite.png', 'error@3x', );
$globe-inverted-name: 'globe-inverted';
$globe-inverted-x: 1086px;
$globe-inverted-y: 675px;
$globe-inverted-offset-x: -1086px;
$globe-inverted-offset-y: -675px;
$globe-inverted-width: 48px;
$globe-inverted-height: 48px;
$globe-inverted-total-width: 1230px;
$globe-inverted-total-height: 798px;
$globe-inverted-image: '~sprite.png';
$globe-inverted: (1086px, 675px, -1086px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'globe-inverted', );
$globe-inverted-2x-name: 'globe-inverted@2x';
$globe-inverted-2x-x: 894px;
$globe-inverted-2x-y: 603px;
$globe-inverted-2x-offset-x: -894px;
$globe-inverted-2x-offset-y: -603px;
$globe-inverted-2x-width: 72px;
$globe-inverted-2x-height: 72px;
$globe-inverted-2x-total-width: 1230px;
$globe-inverted-2x-total-height: 798px;
$globe-inverted-2x-image: '~sprite.png';
$globe-inverted-2x: (894px, 603px, -894px, -603px, 72px, 72px, 1230px, 798px, '~sprite.png', 'globe-inverted@2x', );
$globe-name: 'globe';
$globe-x: 1134px;
$globe-y: 675px;
$globe-offset-x: -1134px;
$globe-offset-y: -675px;
$globe-width: 48px;
$globe-height: 48px;
$globe-total-width: 1230px;
$globe-total-height: 798px;
$globe-image: '~sprite.png';
$globe: (1134px, 675px, -1134px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'globe', );
$globe-2x-name: 'globe@2x';
$globe-2x-x: 966px;
$globe-2x-y: 603px;
$globe-2x-offset-x: -966px;
$globe-2x-offset-y: -603px;
$globe-2x-width: 72px;
$globe-2x-height: 72px;
$globe-2x-total-width: 1230px;
$globe-2x-total-height: 798px;
$globe-2x-image: '~sprite.png';
$globe-2x: (966px, 603px, -966px, -603px, 72px, 72px, 1230px, 798px, '~sprite.png', 'globe@2x', );
$lock-name: 'lock';
$lock-x: 1206px;
$lock-y: 579px;
$lock-offset-x: -1206px;
$lock-offset-y: -579px;
$lock-width: 24px;
$lock-height: 24px;
$lock-total-width: 1230px;
$lock-total-height: 798px;
$lock-image: '~sprite.png';
$lock: (1206px, 579px, -1206px, -579px, 24px, 24px, 1230px, 798px, '~sprite.png', 'lock', );
$lock-2x-name: 'lock@2x';
$lock-2x-x: 1182px;
$lock-2x-y: 675px;
$lock-2x-offset-x: -1182px;
$lock-2x-offset-y: -675px;
$lock-2x-width: 48px;
$lock-2x-height: 48px;
$lock-2x-total-width: 1230px;
$lock-2x-total-height: 798px;
$lock-2x-image: '~sprite.png';
$lock-2x: (1182px, 675px, -1182px, -675px, 48px, 48px, 1230px, 798px, '~sprite.png', 'lock@2x', );
$more-name: 'more';
$more-x: 1182px;
$more-y: 651px;
$more-offset-x: -1182px;
$more-offset-y: -651px;
$more-width: 24px;
$more-height: 24px;
$more-total-width: 1230px;
$more-total-height: 798px;
$more-image: '~sprite.png';
$more: (1182px, 651px, -1182px, -651px, 24px, 24px, 1230px, 798px, '~sprite.png', 'more', );
$more-2x-name: 'more@2x';
$more-2x-x: 0px;
$more-2x-y: 750px;
$more-2x-offset-x: 0px;
$more-2x-offset-y: -750px;
$more-2x-width: 48px;
$more-2x-height: 48px;
$more-2x-total-width: 1230px;
$more-2x-total-height: 798px;
$more-2x-image: '~sprite.png';
$more-2x: (0px, 750px, 0px, -750px, 48px, 48px, 1230px, 798px, '~sprite.png', 'more@2x', );
$more-3x-name: 'more@3x';
$more-3x-x: 1038px;
$more-3x-y: 603px;
$more-3x-offset-x: -1038px;
$more-3x-offset-y: -603px;
$more-3x-width: 72px;
$more-3x-height: 72px;
$more-3x-total-width: 1230px;
$more-3x-total-height: 798px;
$more-3x-image: '~sprite.png';
$more-3x: (1038px, 603px, -1038px, -603px, 72px, 72px, 1230px, 798px, '~sprite.png', 'more@3x', );
$next-name: 'next';
$next-x: 48px;
$next-y: 750px;
$next-offset-x: -48px;
$next-offset-y: -750px;
$next-width: 48px;
$next-height: 48px;
$next-total-width: 1230px;
$next-total-height: 798px;
$next-image: '~sprite.png';
$next: (48px, 750px, -48px, -750px, 48px, 48px, 1230px, 798px, '~sprite.png', 'next', );
$next-2x-name: 'next@2x';
$next-2x-x: 1110px;
$next-2x-y: 603px;
$next-2x-offset-x: -1110px;
$next-2x-offset-y: -603px;
$next-2x-width: 72px;
$next-2x-height: 72px;
$next-2x-total-width: 1230px;
$next-2x-total-height: 798px;
$next-2x-image: '~sprite.png';
$next-2x: (1110px, 603px, -1110px, -603px, 72px, 72px, 1230px, 798px, '~sprite.png', 'next@2x', );
$question-mark-name: 'question-mark';
$question-mark-x: 1206px;
$question-mark-y: 651px;
$question-mark-offset-x: -1206px;
$question-mark-offset-y: -651px;
$question-mark-width: 20px;
$question-mark-height: 20px;
$question-mark-total-width: 1230px;
$question-mark-total-height: 798px;
$question-mark-image: '~sprite.png';
$question-mark: (1206px, 651px, -1206px, -651px, 20px, 20px, 1230px, 798px, '~sprite.png', 'question-mark', );
$question-mark-2x-name: 'question-mark@2x';
$question-mark-2x-x: 96px;
$question-mark-2x-y: 750px;
$question-mark-2x-offset-x: -96px;
$question-mark-2x-offset-y: -750px;
$question-mark-2x-width: 40px;
$question-mark-2x-height: 40px;
$question-mark-2x-total-width: 1230px;
$question-mark-2x-total-height: 798px;
$question-mark-2x-image: '~sprite.png';
$question-mark-2x: (96px, 750px, -96px, -750px, 40px, 40px, 1230px, 798px, '~sprite.png', 'question-mark@2x', );
$logo-name: 'logo';
$logo-x: 750px;
$logo-y: 480px;
$logo-offset-x: -750px;
$logo-offset-y: -480px;
$logo-width: 450px;
$logo-height: 51px;
$logo-total-width: 1230px;
$logo-total-height: 798px;
$logo-image: '~sprite.png';
$logo: (750px, 480px, -750px, -480px, 450px, 51px, 1230px, 798px, '~sprite.png', 'logo', );
$placeholder-name: 'placeholder';
$placeholder-x: 0px;
$placeholder-y: 0px;
$placeholder-offset-x: 0px;
$placeholder-offset-y: 0px;
$placeholder-width: 750px;
$placeholder-height: 750px;
$placeholder-total-width: 1230px;
$placeholder-total-height: 798px;
$placeholder-image: '~sprite.png';
$placeholder: (0px, 0px, 0px, 0px, 750px, 750px, 1230px, 798px, '~sprite.png', 'placeholder', );
$slice-2x-name: 'slice@2x';
$slice-2x-x: 750px;
$slice-2x-y: 0px;
$slice-2x-offset-x: -750px;
$slice-2x-offset-y: 0px;
$slice-2x-width: 480px;
$slice-2x-height: 480px;
$slice-2x-total-width: 1230px;
$slice-2x-total-height: 798px;
$slice-2x-image: '~sprite.png';
$slice-2x: (750px, 0px, -750px, 0px, 480px, 480px, 1230px, 798px, '~sprite.png', 'slice@2x', );
$spritesheet-width: 1230px;
$spritesheet-height: 798px;
$spritesheet-image: '~sprite.png';
$spritesheet-sprites: ($back-inverted, $back-inverted-2x, $back, $back-2x, $chevron-down-small-inverted, $chevron-down-small-inverted-2x, $chevron-down-small, $chevron-down-small-2x, $chevron-up-small-inverted, $chevron-up-small-inverted-2x, $chevron-up-small, $chevron-up-small-2x, $circle-exclamation, $circle-exclamation-2x, $circle-exclamation-3x, $envelope, $envelope-2x, $error, $error-2x, $error-3x, $globe-inverted, $globe-inverted-2x, $globe, $globe-2x, $lock, $lock-2x, $more, $more-2x, $more-3x, $next, $next-2x, $question-mark, $question-mark-2x, $logo, $placeholder, $slice-2x, );
$spritesheet: (1230px, 798px, '~sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
