$science: #1f7bff;
$bliss: #c2ddff;
$navy: #042949;
$storm: #afc6dd;
$blossom: #fff3f5;
$watermelon: #f53b59;
$quarz: #90acc9;
$chamber: #39648d;
$zink: #d2dfed;
$cloud: #e1eeff;
$steam: #f2f8ff;
$care: #4897ff;
$silver: #e8eff7;
$lunar: #6d8dae,;