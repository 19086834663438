@value navbar-height, safe-area from "~@base-styles/metrics.module.css";

.app {
  width: auto;
  height: auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: env(safe-area-inset-top);
}

.app .nodeTitle {
  text-align: center;
}

.loading {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.contentArea {
  display: block;
  padding-bottom: 100px;
  overflow: auto;
  height: auto;
  position: absolute;
  top: navbar-height;
  bottom: safe-area;
  left: 0;
  right: 0;
}

.contentArea > div {
  width: 100%;
}

@media all and (min-width: 992px) {
  .contentArea {
    max-height: calc(100vh - 3.75rem);
    grid-row: 2/4;
    bottom: 0;
  }
}

.content {
  display: block;
}

.error {
  margin: 1rem auto;
  text-align: center;
}
