@import '@base-styles/_colors';

.notFoundContainer {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    text-align: center;
  }
  .notFoundImage {
    margin-bottom: 40px;
  }
  .headline {
    font-size: 1.375rem;
    line-height: 2rem;
    margin: 0;
    margin-bottom: 48px;
  }
  .text {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    a {
      color: $science;
    }
  }
}
